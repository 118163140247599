.cs-place {
    margin: 0 !important;
    background: white;
    border-radius: 19px;
    padding: 4px 10px;
    font-size: 14px;
}

.cs-itext {
    font-size: 14px;
    text-transform: uppercase;
}

.ls-itext {
    font-size: 14px;
    text-transform: uppercase;
}

.place-sub {
    color: #3985F5;
}

.img-blender {
    mix-blend-mode: difference;
}

.league-item {
    cursor: pointer;
}

.league-drawer .ant-drawer-body {
    background-color: #F2F3F5;
    padding: 0 !important;
}

.cs-info-small {
    text-align: center;
    font-size: 24px;
    color: white;
}

.cs-info-wrap-small {
    position: absolute;
    width: 100%;
    height: auto;
    top: 40%;
    left: 0;
}

.ls-date {
    padding-top: 6px;
    font-size: 14px;
    color: #242424;
}

.ls-type {
    padding: 3px 20px;
    border: 1px solid #242424;
    border-radius: 30px;
    font-size: 14px;
    color: #242424;
}

.prize {
    color: #242424;
    font-size: 24px;
    text-transform: uppercase;
}

.team-s-logo {
    /* width: 120px; */
    padding: 0x;
    height: 90px;
}

.team-s-card {
    cursor: pointer;
    border-radius: 8px;
}

.team-b-card {
    cursor: pointer;
    border-radius: 8px;
}

.team-b-card .ant-card-body {
    display: flex;
    align-items: center;
    padding: 5px !important;
    justify-content: space-between;
}

.team-s-card .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px !important;
}

.card-s-title {
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    color: #242424;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
}

.drawer-img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.b-list-img {
    width: 40px;
}

.b-bg-speed {
    background: #271D9A;
    color: white;
}

.b-bg-power {
    background: #FCFE74;
    color: rgb(0, 0, 0);
}

.b-bg-energy {
    background: #640008;
    color: white;
}

.b-tab {
    height: 70px;
    background: #e1e1e1;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
}

.w100 {
    width: 100%;
    padding: 2%;
    align-items: center;
}


h1 {
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 40px;
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.time-wrapper {
    position: relative;
    width: 80px;
    height: 60px;
    font-size: 48px;
    font-family: "Montserrat";
}

.time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
}

.time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
}

.time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
}

.banner-img {
    width: 100%;
    object-fit: cover;
    /* max-height: 350px; */
}

.cs-img {
    max-height: 250px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .cs-itext {
        font-size: 12px !important;
    }

    .ls-itext {
        font-size: 10px !important;
    }
}