.layout {
    width: "100%";
    background: "#e7e9ed";
}

.match-wrap {
    position: relative;
    border-radius: 5px;
    min-height: 400px;
    background-size: cover;
}

.match-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #0000009c;
    padding: 20px 40px;
    color: white;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
}

.match-result-cup {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #0000009c;
    width: 100%;
    padding: 10px 24px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cup {
    max-width: 40px;
}

.cup-amount {
    color: #FFF500;
    font-size: 30px;
    margin-left: 16px;
    font-weight: 600;
}

.team-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.team {
    width: 100px;
    height: 100px;
    background: white;
    padding: 16px;
    border-radius: 50px;
    object-fit: cover;
}

.team-list {
    display: block;
}

.team-eligble {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.one-dot-active {
    width: 20px;
    height: 35px;
    background: linear-gradient(40deg, #0300ff, #9e94f3);
    transform: skewX(345deg);
    border-radius: 6px;
    margin-right: 3px;
}

.one-dot-deactive {
    width: 20px;
    height: 35px;
    background: linear-gradient(40deg, #515151, #818181);
    transform: skewX(345deg);
    border-radius: 6px;
    margin-right: 3px;
}

.team-count {
    color: #3985F5;
    font-size: 24px;
    font-weight: 700;
    margin-left: 10px;
}

.team-data {
    background: white;
    padding: 10px;
    border-radius: 5px;
    height: 545px;
}

.team-player {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 460px;
    overflow: auto;
    margin-top: 10px;
    cursor: pointer;
}

.tournament-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: white;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    align-items: center;
    cursor: pointer;
}

.tournament-icon {
    width: 200px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
}

.avatar-tournament {
    max-width: 140px;
    margin-bottom: 2px;
}

.avatar-name {
    text-align: center;
    margin-bottom: 16px;
    background: #000000a8;
    color: white;
    padding: 6px 6px;
    border-radius: 5px;
}

.team-selected {
    border: solid #ff0000 4px;
    box-shadow: 0 0.5rem 1.2rem rgb(26 26 26 / 80%);
}

.team-selected-2 {
    border: solid #0077ff 4px;
    box-shadow: 0 0.5rem 1.2rem rgb(26 26 26 / 80%);
}

.active-item {
    border: solid #0077ff 2px;
    box-shadow: 0 0.5rem 1.2rem rgb(26 26 26 / 80%);
}

.match-badge {
    position: absolute;
    top: 0px;
    right: 22%;
    background: white;
    padding: 2px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
    border: solid #8080807a 3px;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .team-list {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .team-wrap {
        margin-top: 0;
        margin-right: 10px;
    }
}