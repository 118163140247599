.maring-top-20{
    margin-top: 20px;
}
.maring-top-30{
    margin-top: 30px;
}
.maring-top-40{
    margin-top: 40px;
}
.padding-10{
    padding: 10px;
} 
.team-card{
    cursor: pointer;
    border-radius: 8px;
    min-height: 330px;
    /* width: 300px; */
}
.team-card .ant-card-body{
    display: flex;
    flex-direction: column;
    align-items: center; 
}
.booster-active{ 
    background: rgba(144, 224, 64, 0.4);
    border-radius: 32px;
    width: fit-content;
    padding: 2px 10px;
    font-weight: 600;
}
.booster-active-wrap{
    width: 100%;
    padding: 10px 0px  
}
.team-logo{
    /* width: 120px; */
    padding: 25px 0px 10px 0px;
    min-width: 120px;
    height: 180px;
}
.card-title{
    width: 100%;
    padding: 10px 0px;
    text-align: center;  
    color: #242424;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}
.team-manage-btn{
    height: 42px;
    border-radius: 5px;
    max-width: 150px;
}
.manage-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
}