.layout{
    background-color: #e7e9ed;
    padding: 10px 2%;
    width: 100%;
    min-height: 80vh;
}
.d-flex{
    display: flex;
}
.justify-center{
    justify-content: center;
}
.justify-end{
    justify-content: end;
}
.overflowX{
    overflow-x: auto;
}
.align-right{
    text-align: right;
}
.align-left{
    text-align: left;
}
.align-center{
    text-align: center;
}
.padding-10{
    padding: 10px;
} 
.padding-20{
    padding: 20px;
} 
.padding5{
    padding: 5%;
}
.padding10{
    padding: 10%;
}
.maring-top-5{
    margin-top: 5px;
}
.maring-top-10{
    margin-top: 10px;
}
.maring-top-20{
    margin-top: 20px;
}
.maring-top-30{
    margin-top: 30px;
}
.maring-top-40{
    margin-top: 40px;
}
.mt-25vh{
    margin-top: 25vh;
}

.maring-bt-10{
    margin-bottom: 10px;
}
.maring-bt-20{
    margin-bottom: 20px;
}
.maring-bt-30{
    margin-bottom: 30px;
}
.maring-bt-40{
    margin-bottom: 40px;
}

.margin-right-5{
    margin-right: 5px;
}
.margin-right-10{
    margin-right: 10px;
}
.margin-right-20{
    margin-right: 20px;
}

.margin-left-5{
    margin-left: 5px;
}
.margin-left-10{
    margin-left: 10px;
}
.margin-left-20{
    margin-left: 20px;
}
.justify-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.justify-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pos-rel{
    position: relative;
}
.pos-abs{
    position: absolute;
}

.team-title{
    font-size: 64px;
    font-weight: 700;
    color: #242424;
    text-transform: uppercase;
}
.team-logo{
    /* width: 120px; */
    padding: 25px 0px 10px 0px;
    min-width: 120px;
    height: 150px; 
}
.team-add{
    font-size: 100px;
    color: #3985F5;
    padding: 25px 0px 10px 0px;
}
.team-list-wrap{
    min-height: 330px;
    max-height: 340px;
    overflow-y: auto;
}
.team-card{
    cursor: pointer;
    border-radius: 8px;
    min-height: 330px;
}
.team-card .ant-card-body{
    display: flex;
    flex-direction: column;
    align-items: center; 
}
.booster-active{ 
    background: rgba(144, 224, 64, 0.4);
    border-radius: 32px;
    width: fit-content;
    padding: 5px 20px;
    font-weight: 600;
    text-transform: capitalize;
    height: 32px;
}
.booster-active-wrap{
    width: 100%;
    padding: 10px 0px;
    min-height: 60px;
}
.card-title{
    width: 100%;
    padding: 10px 0px;
    text-align: center;  
    color: #242424;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}
.dotted-border{
    border: dashed 2px #bdbdbd;
}
.close-btn{
    background: #f2f3f5;
    padding: 7px;
    border-radius: 20px;
    font-size: 20px;
    cursor: pointer;
}
.close-btn-wrap{
    position: absolute;
    top: 30px;
    left: -45px;
}
.team-drawer .ant-drawer-body{
    background-color: #F2F3F5;
}
.drawer-title{
    font-size: 36px;
    font-weight: 700;
    color: #242424;
    text-transform: uppercase;
}
.team-name{
    color: #242424;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    padding: 20px 0 10px 0;
}
.enter-name{
    color: #242424;
    font-size: 16px;
    margin-bottom: 10px;
}
.name-box{
    background: #DEDEDE; 
    border-radius: 8px;
    height: 42px;
    color: #242424;
}
.add-logo{ 
    text-transform: uppercase;
}
.logo-add{
    font-size: 100px;
    color: #3985F5;
    padding: 15px 0px 10px 0px;
}
.logo-card{
    cursor: pointer;
    border-radius: 8px; 
    min-height: 200px;
}
.logo-card .ant-card-body{
    display: flex;
    flex-direction: column;
    align-items: center; 
}
.team-create-btn{
    height: 42px;
    border-radius: 5px;
}

.logo-list-wrap{
    overflow-y: auto;
    max-height: 450px;
    width: 100%;
}
.use-logo{
    width: 100px;
    padding: 15px 0px 10px 0px;
}


.primary-btn{
    background-color: #3985F5;
    padding: 0px 25px;
    height: 40px; 
    border-radius: 5px;
    text-transform: uppercase;
}
.warn-btn{
    background-color: #ff651e;
    padding: 0px 25px;
    height: 40px; 
    border-radius: 5px;
    text-transform: uppercase;
    border: none;
}
.second-btn{ 
    padding: 0px 25px;
    height: 40px; 
    text-transform: uppercase;
}
.grey-btn{
    background-color: #A3A5A9 !important;
    padding: 0px 25px;
    height: 40px; 
    border-radius: 5px;
    text-transform: uppercase;
    border: #A3A5A9;
}
.grey-btn:hover{
    background-color: #999999 !important;
}
/* detail page additional style */
.level-value{
    color: #242424; 
    font-weight: 600;
    font-size: 32px;
}
.level-type{
    color: #242424;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;

}
.team-detail-logo{
    width: 100px; 
}
.team-detail .team-title{
    font-size: 48px;
    font-weight: 700;
    color: #242424;
    text-transform: uppercase;
}
.player-card-wrap{
    background: grey;
    padding: 25px;
    border-radius: 10px;
}
.player-card-wrap-white{
    background: #FFFFFF;
    padding: 25px;
    border-radius: 10px;
}
.wrap-title{
    padding: 10px 0px;
    color: #bdbdbd;
}
.h-gap{
    padding: 0px 10px
}
.quick-match-btn{
    height: 70px;
    background-color: #90E040;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}
.quick-btn-text{
    color: #242424;
    font-size: 32px;
    text-transform: uppercase;
}
.quick-logo{
    width: 30px;
    height: 30px;
}
.sub-team-title{
    color: #242424;
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 700;
}
.control-dots {
    top: 10;
}
.cs-wrap{
    position: relative; 
}
.cs-img2{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.cs-info-wrap{
    position: absolute;
    width: 100%;
    height: auto;
    top:5%;
    left: 0;
}
.cs-f-wrap{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.cs-date{
    padding-top: 6px;
    font-size: 14px;
    color: #fff;
    margin-left: 15px;
}
.cs-type{
    padding: 3px 20px;
    border: 1px solid #fff;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    margin-right: 15px;
}
.cs-info{
    text-align: center;
    font-size: 40px;
    color: white;
    margin-top: 10%;
}
.league-carousel div:nth-child(2){
    margin: 0;
}
.league-carousel  div:nth-child(3){
    bottom: 10px;
}
.prize-wrap{
    background: white;
    padding: 3%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.prize-wrap .prize{
    color: #242424;
    font-size: 24px;
    text-transform: uppercase;
}
.prize-item{
    
}
.league-carousel .prize-wrap div:nth-child(3){
    bottom: 0px;
}
.camp-img{
    background: #3985F5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}
.camp-text{
    position: absolute;
    top: 5%;
    text-align: center;
    width: 100%;
    color: white;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
}
.reward-btn-wrap{
    min-height: 40px;
    margin-top: 6px;
}
.trainer-item{
    padding: 8px;
    min-width: 150px;
    max-width: 200px;
}
.trainer-item-b{
    padding: 8px;
    min-width: 220px;
    max-width: 230px;
}
.trainer-img{
    width: 100%;
}
.booster-img{
    width: 100%;    
    /* max-width: 140px; */
    top: 5px;
    left: 5px;
    background-color: #1e8ae93b;
    border-radius: 5px;
}
.rewarder-img{
    width: 25%;
    min-width: 120px;
}
.trainer-time{
    text-align: center; 
    padding: 7px 25px;
    font-size: 16px;
}
.trainer-card{
    cursor: pointer;
    border-radius: 8px; 
    max-height: 186px;
}
.tab-box{
    width: 100%;
    background-color: #61616A;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.tab-box .ant-tabs-tab{
    padding: 10px 30px;  
}
.tab-box .ant-tabs-tab-active{
    padding: 2px 0px;
}
.tab-box .ant-tabs-tab-active .ant-tabs-tab-btn{
    background: #FFFFFF;
    padding: 10px 60px;
    border-radius: 5px;
    color: #61616A !important; 
}
.tab-box .ant-tabs-ink-bar{
    background: none !important;
}
.tab-box .ant-tabs-tab-active .ant-tabs-tab-btn:hover{
    color: #61616A !important; 
}
.tab-box .ant-tabs-nav-list{ 
    justify-content: space-between;
}
.tab-box .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 0px;
}
.tab-price{
    font-size: 24px;
    margin-left: 10px;
    text-transform: uppercase;
}
.reward-text{
    font-weight: 700;
    font-size: 24px;
}
.rewarder-card-wrap{
    background: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: start;
}
.rewarder-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}
.rewarder-sport-type{
    text-transform: uppercase;
    color: #61616A;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}
.rewarder-name{
    font-weight: 700;
    font-size: 24px; 
    align-items: flex-end;
    color: #242424;
}
.add-card{
    cursor: pointer;
    width: 15rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed #d7d7d7;
    border-radius: 10px;
    min-height: 260px;
}
.add-card .ant-card-body{
    text-align: center;
}
.serach-wrap{
    width: 100%; 
}
.search-box{
    width: 100%;
    height: 42px; 
    border-radius: 8px;
    background: #DEDEDE;
    color: #737373;
    font-size: 16px;
    padding: 0px 10px;
    position: relative;
}
.search-icon{
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
}
.notify-msg{
    color: #242424;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}
.notify-sub-msg{
    color: #242424; 
    text-align: center;
    font-size: 16px;
}
.add-player-btn{
    position: absolute;
    bottom: 10px;
}
.add-player{
    width: 100px;
    padding: 5px 0px 5px 0px;
}
.sel-btn{
    position: absolute;
    top: 10px;
    right: 10px;
}
.opt-out{
    width: 25px;
    height: 25px;
    background-color: #6666665c;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.opt-in{
    width: 16px;
    height: 16px;
    background-color: #90E040;
    border-radius: 25px;
}
.sport-sel{
    border: none !important;
    width: 100%;
    padding: 10px 10px;
}
.booster-info{
    position: absolute; 
    left: 16px;
    top: 16px;
    background: #efefef;
    padding: 0px 10px;
    border-radius: 8px;
}
.booster-plus{
    position: absolute;
    top: 16px;
    right: 16px;
    background: #efefef;
    padding: 0px 5px;
    border-radius: 8px;
}
.booster-btn{
    font-size: 16px;
    cursor: pointer;
}
.ant-upload-select {
    display: none !important;
}
.ads-info{
    text-align: center;
    background: #738bff;
    color: white;
    font-size: 12px;
    border-radius: 3px;
    padding: 5px;
}
.ads-item-wrap{
    margin-right: 15px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 15px;
}
.ads-logo{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover; 
}
.player-title{
    text-align: center;
    font-size: 12px;
}
@media only screen and (max-width: 600px) {
    .team-detail-logo{
        width: 70px; 
    }
    .team-detail .team-title{
        font-size: 36px; 
    }
    .cs-info{ 
        font-size: 16px; 
    }
    .rewarder-name { 
        font-size: 16px; 
    }
    .camp-text{
        font-size: 32px;
    }
    .margin-right-10{
        margin-right: 5px ;
    }
}