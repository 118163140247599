.profile_layout{
    max-width: 1440px;
    margin: auto;
}
.profile-sect-wrap{
    min-height: 75vh;
    margin-top: 40px;
}
.profile-img{
    max-height: 240px;
}
.flex-center{
    display: flex; 
    justify-content: center;
}
.profile-info{
    font-size: 16px;
    font-weight: bold;
}
.profile-info-data{
    font-size: 16px;
    font-weight: bold;
    color: #363636;    
}
.flex-item-center{
    display: flex;
    align-items: center;
    justify-content: start;
}
.team-logo-sm{
    max-width: 80px;
    margin-right: 30px;
    margin-left: 30px;
}
.links{
    color: #0074ab;
}
.card-wrap{
    background: white;
    padding: 30px 5%;
    border-radius: 5px;
}
.tx-capital{
    text-transform: capitalize;
}